
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import store from "@/store";
import Bank from "@/components/reusable/Bank.vue";
import { ErrorMessage, Field, Form } from "vee-validate";

export default defineComponent({
  name: "motor-insurance-claims-discharge-voucher",
  components: {
    ErrorMessage,
    Field,
    Form,
    Bank,
  },
  props: ["publicId"],
  data() {
    return {
      // publicId: this.$route.params.publicId,
      claim: {},
      signature: "",
      bankPublicId: "",
      bankBranch: "",
      accountName: "",
      accountNumber: "",
      accountType: "",
      witnessName: "",
      witnessPhoneNumber: "",
    };
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const user = computed(() => {
      return store.getters.currentUser;
    });

    const submitDischargeVoucher = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const formData = new FormData();

            for (const [key, value] of Object.entries(values)) {
              formData.append(key, value as string | Blob);
            }

            ApiService.setHeader();
            ApiService.post(
              `/settlement/${props.publicId}/discharge-voucher`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                // variables.toastAlert(response.data.data.message, "success");
                variables.toastAlert(
                  "Discharge voucher was successfully saved",
                  "success"
                );
                resetForm();
                // Redirect back to claims history page
                router.push({
                  name: "cooperative-admin-members-claims-listing",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Discharge Voucher", [
        "Claims",
        "Motor Insurance Claim",
      ]);
    });

    return { submitButton1, submitDischargeVoucher, user };
  },
  created() {
    this.getClaimInformation();

    //Set page title
    document.title =
      "Cooperative: Motor Insurance Claim Discharge Voucher | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    getClaimInformation() {
      const router = useRouter();
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.COOPERATIVE_CLAIMS_ROUTE}/${this.publicId}`)
          .then(({ data }) => {
            //Assign data to variables
            // console.clear();
            // console.log(data.data);

            this.claim = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    },
    capitalizeWord(value) {
      return variables.capitalizeWord(value);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    acceptNumberOnly(amount) {
      return variables.acceptNumberOnly(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    signatureSelected(event) {
      this.signature = event.target.files[0];
    },
  },
});
