
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "bank-component",
  components: { Field, ErrorMessage },
  props: {
    widgetClasses: String,
    selectedOption: String
  },
  data() {
    return {
      banks: ""
    };
  },
  mounted() {
    this.getbanks();
  },
  methods: {
    getbanks() {
      //Fetch list of banks in Nigeria
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.BANK_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.banks = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    capitalize(value) {
      return variables.capitalize(value);
    }
  }
});
